import React from 'react';

import sphere from '../../assets/images/spheres.png';

const SphereListItem = ({ children }) => (
  <li className="w-full flex flex-row gap-4 justify-start items-center">
    <img
      loading="lazy"
      src={sphere}
      width="17"
      height="17"
      className="mb-0"
    />
    <span className="xl:text-lg text-base text-blue">
      {children}
    </span>
  </li>
);

export default SphereListItem;
