import React from 'react';

import { PrismicRichText } from '@prismicio/react';

import { FormButton } from '../../frontend-shared/components';

import Link from './Link';
import SphereListItem from './SphereListItem';

const LinearContentBlock = ({
  entries = [],
  shadow = false,
  orient = 'horizontal', // horizontal | vertical | twist
}) => (
  <div className="w-full px-4 lg:px-8 xl:px-0 flex flex-col gap-24 pt-24 pb-8">
    {entries.map(({ title, content, image, button_text, button_link }, index) => {
      const button = button_text && (
        <Link className="no-underline" href={button_link}>
          <FormButton color="secondary">
            {button_text}
          </FormButton>
        </Link>
      );
      const vertical = orient === 'vertical';
      const matter = (className, renderButton = true) => (
        <div className={['flex-col', className, vertical ? 'mx-auto items-center w-full max-w-[970px]' : 'xl:pr-28'].filter(Boolean).join(' ')}>
          <h3
            className={[
              'text-blue lg:text-5xl md:text-4xl text-3xl font-medium md:mb-8 mb-6 tracking-tighter',
              vertical ? 'text-center' : '',
            ].filter(Boolean).join(' ')}
          >
            {title}
          </h3>
          <PrismicRichText
            field={content.richText || content}
            components={{
              paragraph: ({ children }) => (
                <p
                  className={[
                    'text-blue sm:text-xl text-lg',
                    vertical ? 'w-full !max-w-full' : '',
                  ].filter(Boolean).join(' ')}
                >
                  {children}
                </p>
              ),
              listItem: SphereListItem,
              list: ({ children }) => (
                <ul className="w-full">
                  {children}
                </ul>
              ),
              olist: ({ children }) => (
                <ol className="w-full">
                  {children}
                </ol>
              ),
            }}
          />
          {renderButton && button && (
            <div className="hidden md:flex pt-8">
              {button}
            </div>
          )}
        </div>
      );
      const poster = image?.fluid?.srcWebp ? (
        <div className={vertical ? 'flex justify-center w-full' : undefined}>
          <img
            alt={title}
            loading="lazy"
            className={[
              'rounded-2xl',
              shadow ? ' shadow-xl' : '',
              vertical ? 'max-w-[680px]' : '',
            ].filter(Boolean).join(' ')}
            src={image?.fluid?.srcWebp}
            srcset={image?.fluid?.srcSetWebp}
            height={image?.dimensions?.height}
            width={image?.dimensions?.width}
          />
        </div>
      ) : null;
      return (
        <div
          className={[
            vertical ? '' : 'md:grid-cols-2',
            'grid grid-cols-1 md:gap-6 gap-4 items-center md:mb-12 mb-16',
          ].filter(Boolean).join(' ')}
          key={title}
        >
          {vertical ? (
            <>
              {matter('flex', false)}
              {poster}
            </>
          ) : orient === 'twist' && index % 2 ? (
            <>
              {matter('md:hidden flex')}
              {poster}
              {matter('hidden md:flex')}
            </>
          ) : (
            <>
              {matter('flex')}
              {poster}
            </>
          )}
          {button && (
            <div className={`flex ${vertical ? '' : ' md:hidden'} items-center justify-center`}>
              {button}
            </div>
          )}
        </div>
      );
    })}
  </div>
);

export default LinearContentBlock;
